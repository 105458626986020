import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About" />
    <div>
      <h2>Who am I?</h2>
      <p>I'm a 4th year student at the University of Victoria, in the Bachelor of Software Engineering program. Some quick facts: </p>
      <ul>
        <li>I'm passionate about the web, and reaching lots of people with my projects</li>
        <li>If I'm not in front of a computer, I'm probably working on one of my cars - my current project is a 1989 BMW 325i</li>
        <li>In the winter, I like to spend as much time as possible on the ski hill</li>
      </ul>
      <Link to="/">Go back to the homepage</Link>
    </div>

  </Layout>
)

export default About
